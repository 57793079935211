const awsmobile = {
  "aws_project_region": "us-west-2",
  "aws_cognito_identity_pool_id": "us-west-2:faec5a74-98c7-42ea-a80c-257c19c72af2",
  "aws_cognito_region": "us-west-2",
  "aws_user_pools_id": "us-west-2_nxhXsQXjY",
  "aws_user_pools_web_client_id": "3sjmg52e72khq56k6pd8a597gi",
  "oauth": {
    "domain": "empath-auth-staging.auth.us-west-2.amazoncognito.com",
    "scope": [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin"
    ],
    "responseType": "code",
    "redirectSignIn": "https://staging.empath.io/login",
    "redirectSignOut": "https://staging.empath.io/logout"
  },
  "federationTarget": "COGNITO_USER_POOLS",
  "aws_appsync_graphqlEndpoint": "https://6gijuns2bjckzc2plxnqgofwlq.appsync-api.us-west-2.amazonaws.com/graphql",
  "aws_appsync_region": "us-west-2",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  "aws_mobile_analytics_app_id": "e2240eff26504313ba26b829de32bf80",
  "aws_mobile_analytics_app_region": "us-west-2",
  "aws_user_files_s3_bucket": "empath-staging-us-west-2-storage",
  "aws_user_files_s3_bucket_region": "us-west-2",
  "cookieStorage": {
    "domain": ".empath.io",
    "secure": true
  }
}; 

export default awsmobile;