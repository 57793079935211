import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';

const CloudSupportEscalationPlanLink = () => (
  <a
    href="https://www.effectual.com/wp-content/uploads/2023/01/Effectual-Cloud-Support-Escalation-Plan.pdf"
    target="_blank"
    rel="noreferrer"
    className="flex m-4 hover:text-orange"
  >
    View Cloud Support Escalation Plan
    <span className="ml-2">
      <FontAwesomeIcon icon={faExternalLink} fixedWidth />
    </span>
  </a>
);

export default CloudSupportEscalationPlanLink;
